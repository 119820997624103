import { assetsUri, downloadAssetsUri } from '@/app/_services/api.service';

import { DirectusFile, FilesMeta } from '../_types/file';

/**
 *
 * @param image Directus file ID
 * @returns Directus full image url
 */

export const getDirectusAssetURI = (image: string | null, download = false) => {
  if (image != null) {
    let url = `${assetsUri}/${image}`;

    if (download) {
      url = `${url}?download`;
    }
    return url;
  }
  return null;
};

/**
 *
 * @param file Directus file ID
 * @returns Directus full image url
 */

export const getDirectusDownloadAssetURI = (file: string | null, download = false) => {
  if (file != null) {
    let url = `${downloadAssetsUri}/${file}`;

    if (download) {
      url = `${url}?download`;
    }
    return url;
  }
  return null;
};

/**
 *
 * @param file Directus file ID
 * @returns Directus full image url
 */

export const getProxiedDownloadAssetURI = (fileInfo: DirectusFile | undefined | "", download = false) => {
  if (fileInfo) {
    let url = `/files/${encodeURIComponent(fileInfo.filename_download)}`;

    if (download) {
      url = `${url}?download`;
    }
    return url;
  }
  return null;
};

/**
 * Returns the alt property value for a specific file in the FilesMeta object
 * 
 * @param filesMeta The object containing the metadata for multiple files
 * @param fileId The file id of the file we're interested in
 * @param defaultValue If no metadata was found, use this value
 * @returns 
 */
export const getAltProperty = (filesMeta: FilesMeta, fileId: string, defaultValue: string): string => {
  if (filesMeta && fileId) {
    const meta = filesMeta[fileId];

    if (meta) {
      return meta.title;
    }
  }
  return defaultValue;
};
